<template>
</template>
<script>

import {mapGetters} from "vuex";
import joinApi from '@/api/join'
import CONF from '@/config.js'

export default {
    name: 'tile_inicis_bill_key',
    components: {},
    data: () => ({
        loaded: false,
        testUrl: ''
    }),
    computed: {
        ...mapGetters({
            joinTid: 'join/getJoinTid',
            joinFormStep03: 'join/getJoinFormStep03',
            joinFormStep04: 'join/getJoinFormStep04',
            product: 'join/getProduct'
        }),
        nextPayAmount() {
            let staffCount = 1
            let productPrice = 0
            let discountRatio = 0

            if (this.product && this.product.product_price) {
                productPrice = this.product.product_price
            }
            if (this.product && this.product.default_discount_ratio && this.product.default_discount_ratio > 0) {
                discountRatio = this.product.default_discount_ratio
            }

            return staffCount * (productPrice * (100 - discountRatio) / 100)
        }
    },
    created() {
    },
    mounted() {
        const plugin = document.createElement("script")
        plugin.setAttribute("src", "https://stdpay.inicis.com/stdjs/INIStdPay.js")
        plugin.setAttribute("charset", "UTF-8")
        plugin.async = true;
        document.head.appendChild(plugin)

        let ctx = this
        setTimeout(function () {
            ctx.goPay()
        }, 500)
    },
    methods: {
        redo() {
            this.goPay()
        },
        goPay() {
            let ctx = this
            let param = {}
            param.total_price = this.nextPayAmount
            param.join_tid = this.joinTid

            joinApi.startBillKeyTran(param, (pgReq) => {
                let pgForm = document.createElement('form')
                pgForm.name = 'pgForm'
                pgForm.id = 'pgForm'
                pgForm.method = 'post'
                pgForm.action = CONF.PG_BASE_URL + '/bill'

                let goodname = '콜 PRO 서비스'

                ctx.addHiddens({
                    'version': '1.0',
                    'mid': pgReq.mid,
                    'goodname': goodname,
                    'oid': pgReq.oid,
                    'price': pgReq.price,
                    'currency': 'WON',
                    'buyername': pgReq.buyer_name,
                    'buyertel': pgReq.buyer_tel,
                    'buyeremail': pgReq.buyer_email,
                    'timestamp': pgReq.timestamp,
                    'signature': pgReq.signature,
                    'returnUrl': pgReq.return_url,
                    'mKey': pgReq.m_key,
                    'gopaymethod': '',
                    'offerPeriod': 'M2',
                    'acceptmethod': 'BILLAUTH(card)',
                    'billPrint_msg': '고객님의 매월 결제일은 01일 입니다.',
                    'languageView': '',
                    'charset': '',
                    'payViewType': '',
                    'closeUrl': pgReq.close_url,
                    'popupUrl': pgReq.popup_url,
                    'merchantData': pgReq.merchant_data
                }, pgForm)

                let oldObj = document.getElementById('pgForm')
                if (oldObj) {
                    document.body.removeChild(oldObj)
                }
                document.body.appendChild(pgForm)
                //pgForm.submit()

                INIStdPay.pay('pgForm')
            }, (err, va) => {
                let msg = '결제 서비스 연동을 실패했습니다'
                let subMsg = ''

                if (err == 'NO_TRAN') {
                    msg = '인증시간이 만료되었습니다. 다시 인증해주세요.'
                }

                this.$store.dispatch('openAlert', {
                    'message': msg,
                    'sub_message': subMsg,
                    'closeCb': (va) => {
                        if (err == 'NO_TRAN') {
                            va.$router.push('/join/step01')
                        }
                    },
                    'va': va
                })
            }, this)

        },
        addHiddens(m, f) {
            for (let ke in m) {
                let va = m[ke]

                let i = document.createElement('input')
                i.setAttribute('type', 'hidden')
                i.setAttribute('name', ke)
                i.setAttribute('value', va)
                f.appendChild(i)
            }
        }
    }
}
</script>
<style>
</style>


